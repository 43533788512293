interface SaleData {
	key: string
	sale: string
	visitor: string
	email: string
	products: { id: string; quantity: number; price: number }[]
	customer?: string
}

interface CartData {
	key: string
	visitor: string
	products: { id: string; quantity: number; price: number }[]
	customer?: string
}
export async function logClickEvent(
	productId: string,
	position: number,
	external: boolean,
	visitorId: string,
) {
	const apiKeys = [ENV.CLERK_PUBLIC_KEY, ENV.CLERK_PUBLIC_KEY_DK]
	const apiEndpoint = 'https://api.clerk.io/v2/log/click'

	for (const apiKey of apiKeys) {
		const url = new URL(apiEndpoint)
		url.searchParams.append('key', apiKey)
		url.searchParams.append('product', productId)
		url.searchParams.append('visitor', visitorId)
		url.searchParams.append('n', position.toString())

		try {
			const response = await fetch(url.toString(), {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			await response.json()
		} catch (error) {
			console.error('Error logging click event:', error)
		}
	}
}

// move this to the server

export const logSaleEvent = async (
	saleId: string,
	products: { id: string; quantity: number; price: number }[],
	email: string,
	visitorId: string,
	customerId?: string,
) => {
	const apiKeys = [ENV.CLERK_PUBLIC_KEY, ENV.CLERK_PUBLIC_KEY_DK]

	const apiEndpoint = 'https://api.clerk.io/v2/log/sale'
	for (const apiKey of apiKeys) {
		const saleData: SaleData = {
			key: apiKey,
			sale: saleId,
			visitor: visitorId,
			email: email,
			products: products,
		}

		if (customerId) {
			saleData.customer = customerId
		}

		try {
			const response = await fetch(apiEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(saleData),
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			await response.json()
		} catch (error) {
			console.error('Error logging sale event:', error)
		}
	}
}

export const logAddToCartEvent = async (
	productId: string,
	quantity: number,
	price: number,
	visitorId: string,
	customerId?: string,
): Promise<void> => {
	const apiKeys = [ENV.CLERK_PUBLIC_KEY, ENV.CLERK_PUBLIC_KEY_DK]

	const apiEndpoint = 'https://api.clerk.io/v2/log/cart/add'

	for (const apiKey of apiKeys) {
		const cartData: CartData = {
			key: apiKey,
			visitor: visitorId,
			products: [
				{
					id: productId,
					quantity,
					price,
				},
			],
		}

		if (customerId) {
			cartData.customer = customerId
		}

		try {
			const response = await fetch(apiEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(cartData),
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			await response.json()
		} catch (error) {
			console.error('Error logging add to cart event:', error)
		}
	}
}
